import axios from "axios";
import {CityApi, ConfigApi, ContactApi, Coordinates, ItemResponse, ListResponse, LoginApi, ProfileApi} from "./types";
import {MockApi} from "./Mock/mock-api";
import {isDebug} from "../../utils/envutil";
import applyUnauthorizedAppInterceptor from "./applyUnauthorizedAppInterceptor";

const mockRestApi = axios.create({
    // baseURL: '/api/'
    baseURL: '/data/'
})
// let defaultUrl = "http://store.goorder.local/api/portal/";
let defaultUrl = "https://store.goorder.pl/api/portal/";
const restApi = axios.create({
    baseURL: defaultUrl
    // baseURL: `${getApiURL()}/api/portal/`
    // baseURL: 'https://demoshop.gopos.pl/api/portal/'
    // baseURL: 'https://store.goorder.pl/api/portal/'
    // baseURL: '/data/'
})

applyUnauthorizedAppInterceptor(restApi);
let initializedMock = false;
let source: any;


const generateToken = () => {
    if (typeof source != typeof undefined) {
        source.cancel("Operation canceled due to new request.")
    }
    const CancelToken = axios.CancelToken;

    source = CancelToken.source();
    let token = source.token;
    return token;
}

class api {

    static restoreBaseUrl(){
        restApi.defaults.baseURL = defaultUrl;
    }
    static setBaseUrl(baseUrl : string){
        restApi.defaults.baseURL = baseUrl;
    }
    static async initMock() {
        if (initializedMock) {
            return;
        }
        const profilesResponse = await mockRestApi.get<ListResponse<ProfileApi>>("profiles.json");
        const coordinatesResponse = await mockRestApi.get<ListResponse<ProfileApi>>("coordinates.json");
        const configResponse = await mockRestApi.get<ItemResponse<ConfigApi>>("config.json");
        const cityResponse = await mockRestApi.get<ListResponse<CityApi>>("cities.json");
        MockApi.init(profilesResponse.data.data, coordinatesResponse.data.data, configResponse.data.data, cityResponse.data.data);

        initializedMock = true;
    }

    static async getCoordinates(filter: any) {
        if (isDebug()) {
            // await this.initMock();
            // return MockApi.getCoordinates();
        }
        const response = await restApi.get<ItemResponse<Coordinates>>("coordinates", {params: filter});

        return response.data.data;
    }

    static async getProfiles(filter: any) {
        if (isDebug()) {
            // await this.initMock();
            // return MockApi.getProfiles();
        }
        const profilesResponse = await restApi.get<ListResponse<ProfileApi>>("places", {params: filter});
        // console.log(profilesResponse.data.data);
        // return undefined;
        return profilesResponse.data.data;
    }

    static async getConfig(name: string) {
        if (isDebug()) {
            mockRestApi.defaults.baseURL = "/data/";
            const configResponse = await mockRestApi.get<ItemResponse<ConfigApi>>(`configs/${name}.json`);
            return configResponse.data.data;
            // console.log(configResponse.data.data);
            // await MockApi.init([],[], configResponse.data.data);
            // return MockApi.getConfig();
        }else{
            // mockRestApi.defaults.baseURL = "/portal/data/";
            mockRestApi.defaults.baseURL = "/data/";
        }
        const configResponse = await mockRestApi.get<ItemResponse<ConfigApi>>(`configs/${name}.json`);
        return configResponse.data.data;
        // await MockApi.init([],[], configResponse.data.data);
        // return MockApi.getConfig();
        // return undefined;
        // const profilesResponse = await restApi.get<ListResponse<ProfileApi>>("places", {params: filter});
        // console.log(profilesResponse.data.data);
        // return undefined;
        // return profilesResponse.data.data;

    }

    static async getCities(query : string, params:string[] | undefined) {
        if (isDebug()) {
            await this.initMock();
            return MockApi.getCities();
        }
        let filter = params ? params : {} as any;
        filter.country_code = "PL";
        filter.city = query;
        const response = await restApi.get<ListResponse<CityApi>>("cities", {params: filter});
        return response.data.data;
    }
    static async getStreets(city:string, query : string) {
        // if (isDebug()) {
        //     await this.initMock();
        //     return MockApi.getCities();
        // }
        let filter = {
            country_code: "PL",
            city: city,
            street: query
        }
        const response = await restApi.get<ListResponse<ProfileApi>>("streets", {params: filter});
        return response.data.data;
    }
    static async login(organizationId: string, username:string, password : string) {
        let data = {
            username: username,
            password: password
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`${organizationId}/login`, data);
        return response.data.data;
    }
    static async resetPassword(organizationId: string, email:string) {
        let data = {
            email: email,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`${organizationId}/reset-password`, data);
        return response.data.data;
    }
    static async changePassword(organizationId: string, email:string, code: string, password : string, confirmPassword: string) {
        let data = {
            email: email,
            code: code,
            password: password,
            passwordConfirmation: confirmPassword,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`${organizationId}/change-password`, data);
        return response.data.data;
    }
    static async sendActivationLink(organizationId: string, email:string) {
        let data = {
            email: email,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`${organizationId}/activation-link`, data);
        return response.data.data;
    }
    static async registerActivation(organizationId: string, email:string, code: string) {
        let data = {
            email: email,
            code: code,
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`${organizationId}/register-activation`, data);
        return response.data.data;
    }
    static async register(organizationId: string, email:string, name:string, phone:string, password : string, confirmPassword: string, acceptRules: boolean, acceptMarketingRules: boolean) {
        let data = {
            email: email,
            password: password,
            name: name,
            phone: phone,
            passwordConfirmation: confirmPassword,
            acceptRules: acceptRules,
            acceptMarketingRules: acceptMarketingRules
        }
        const response = await restApi.post<ItemResponse<LoginApi>>(`${organizationId}/register`, data);
        return response.data.data;
    }
    static async getMe(organizationId: string, token : string) {
        let headers = {
            "user-token": token
        }
        const response = await restApi.get<ItemResponse<ContactApi>>(`${organizationId}/login/get`, {headers: headers});
        return response.data.data;
    }
}

export {restApi, api}