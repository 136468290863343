import React, {FC} from "react";
import {ConfigModuleStoreApi, Coordinates, ProfileApi} from "../../../../services/Api/types";
import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api';
import EaterItem from "../Item";

interface Props {
    profiles: ProfileApi[] | undefined,
    coordinates: Coordinates | undefined,
    openStore: (url: any) => void,
    showProfile: string | undefined,
    config?: ConfigModuleStoreApi
}

const containerStyle = {
    // width: '400px',
    // height: '400px'
};

let defaultCenter = {
    lat: 51.9194,
    lng: 19.1451
};
let defaultZoom = 7;
const apiKey = "AIzaSyCvk6de_r1U3oKMbWOY1DztMTWMcxeKMaE";

const EaterMap: FC<Props> = (props) => {
    const [selectedProfile, setSelectedProfile] = React.useState<ProfileApi>()
    const [showMe, setShowMe] = React.useState<boolean>()
    // const [map, setMap] = React.useState(null)
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: apiKey
    // })


    // const onLoad = React.useCallback(function callback(map) {
    //     console.log(map.center.lat());
    //     console.log(map.center.lng());
    //     console.log(isLoaded);
    //     // const bounds = new window.google.maps.LatLngBounds();
    //     // map.fitBounds(bounds);
    //     setMap(map)
    // }, [])
    // const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    // }, [])
    const selectProfile = (profile? : ProfileApi) => {
        setSelectedProfile(profile)
    }

    if (props.profiles === undefined) {
        return null;
    }
    let profiles = props.profiles.slice(0, 100);
    let center = undefined;
    let zoom = defaultZoom;
    if(props.showProfile !== undefined) {
        let showProfile = profiles.filter(x => x.domain === props.showProfile)[0];
        // if(showProfile === undefined){
        //     showProfile = props.profiles.filter(x => x.domain === props.showProfile)[0];
        //     if(showProfile !== undefined){
        //         profiles.push(showProfile);
        //     }
        // }
        if (showProfile !== undefined && showProfile.coordinates !== undefined) {
            center = {
                lat: showProfile.coordinates.latitude,
                lng: showProfile.coordinates.longitude,
            }
            zoom = 11;
        }
    }
    if(center === undefined){
        if(props.coordinates !== undefined){
            center = {
                lat: props.coordinates.latitude,
                lng: props.coordinates.longitude,
            }
            zoom = 11;
        }else{
            center = defaultCenter
        }
    }

    let currentLocationIcon = undefined as string | undefined;
    let markerIcon = undefined as string | undefined;
    if(props.config && props.config.map){
        if(props.config.map.icon_current_location){
            currentLocationIcon = props.config.map.icon_current_location.includes("http") ? props.config.map.icon_current_location : require(`../../../../images/svg/${props.config.map.icon_current_location}/current_marker.svg`).default;
        }
        if(props.config.map.icon_marker){
            markerIcon = props.config.map.icon_current_location.includes("http") ? props.config.map.icon_current_location : require(`../../../../images/svg/${props.config.map.icon_marker}/marker.svg`).default;
        }
    }

    return (
        <div className="eater-map">
            {/*<LoadScript*/}
            {/*    googleMapsApiKey={apiKey}*/}
            {/*>*/}
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    // onLoad={onLoad}
                    // onUnmount={onUnmount}
                >
                    {props.coordinates !== undefined && (
                        <Marker
                            icon={currentLocationIcon ? {
                                url: currentLocationIcon,
                            } : undefined}
                                position={{
                                    lat: props.coordinates.latitude,
                                    lng: props.coordinates.longitude
                                }}
                                onClick={() => setShowMe(true)}
                        >
                            {showMe ? (
                                <InfoWindow
                                    onCloseClick={() => setShowMe(false)}
                                >
                                    <div>Tutaj jesteś</div>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    )}

                    {profiles.map(profile => {
                        if(profile.coordinates === undefined) return undefined;
                        return (
                            <Marker key={profile.store_url}
                                position={{
                                    lat: profile.coordinates.latitude,
                                    lng: profile.coordinates.longitude
                                }}
                                    icon={markerIcon ? {
                                        url: markerIcon,
                                    } : undefined}
                                onClick={() => selectProfile(profile)}
                            >
                                {selectedProfile && selectedProfile.domain === profile.domain ? (
                                    <InfoWindow
                                        position={{
                                            lat: profile.coordinates.latitude,
                                            lng: profile.coordinates.longitude
                                        }}
                                        onCloseClick={() => selectProfile(undefined)}
                                    >
                                        <EaterItem openStore={props.openStore} profile={profile} />
                                    </InfoWindow>
                                ) : null}
                            </Marker>
                        )
                    })}
                    {/*<Marker*/}
                    {/*    position={{ lat: -34.397, lng: 150.644 }}*/}
                    {/*    onClick={props.onToggleOpen}*/}
                    {/*>*/}
                    {/*    {props.isOpen && <InfoWindow onCloseClick={props.onToggleOpen}>*/}
                    {/*        <FaAnchor />*/}
                    {/*    </InfoWindow>}*/}
                    {/*</Marker>*/}
                    { /* Child components, such as markers, info windows, etc. */ }
                    <></>
                </GoogleMap>
            {/*</LoadScript>*/}
        </div>
    );
};

export default EaterMap;
