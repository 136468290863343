import React, {FC} from "react";
import {ProfileApi} from "../../../../services/Api/types";
import {ReactComponent as AngelRightSVG} from "../../../../images/svg/angle-right.svg";
import {ReactComponent as DeliverySVG} from "../../../../images/svg/delivery.svg";
import {ReactComponent as PhoneSVG} from "../../../../images/svg/phone.svg";
import {ReactComponent as MarkerSVG} from "../../../../images/svg/marker.svg";

interface Props {
    profile: ProfileApi | undefined,
    openStore: (url: any) => void,
    showUnavailable?: boolean
}

const EaterItem: FC<Props> = (props) => {
    if (props.profile === undefined) {
        return null;
    }
    let profile = props.profile;

    let openStore = (url:string) => {
        props.openStore(url);
    }
    let renderPhone = (phone: string) => {
        if(phone.startsWith("48")){
            phone = phone.substring(2);
            return "+48 " + phone;
        }
        return phone;
    }

    let renderTypes = (types: string[] | undefined) => {
        if(types === undefined) return null;
        let nameTypes = types.map(type => {
            if(type == 'DELIVERY') return "dostawa";
            if(type == 'PICK_UP') return "odbiór osobisty";
            if(type == 'DINE_IN') return "na miejscu";
        })
        let typeString = nameTypes.join(", ");
        return (
            <small>{typeString}</small>
        )
    }
    let renderDistance = (distance: number | undefined) => {
        if(distance === undefined) return null;
        distance = Math.round((distance + Number.EPSILON) * 100) / 100
        return (
            <div className={"distance"}>{distance} km</div>
        )
    }
    let isNotDeliverZone = props.showUnavailable && (profile.in_delivery_zone !== undefined && !profile.in_delivery_zone);
    return (
       <>
           {isNotDeliverZone && (
               <div className={"profile-item-zone-error"}>Lokal poza strefą dostaw</div>
           )}
           <div className={`profile-item${!isNotDeliverZone ? "" : " profile-item-not-zone"}`}>

               <h5>
                   {profile.name}
                   {renderTypes(profile.types)}
               </h5>
               {profile.header && (<p>{profile.header}</p>)}
               {renderDistance(profile.distance)}
               <ul>
                   {profile.address !== undefined && (
                       <li>
                           <MarkerSVG /> {profile.address.street} {profile.address.build_nr}, {profile.address.zip_code && profile.address.zip_code} {profile.address.city}
                       </li>
                   )}
                   {profile.phone_number !== undefined && (
                       <li>
                           <PhoneSVG />  {renderPhone(profile.phone_number)}
                       </li>
                   )}
                   {profile.delivery && (
                       <>
                           {profile.delivery.delivery_price && (
                               <li>
                                <span>
                                    <DeliverySVG /> {profile.delivery.delivery_price}
                                </span>
                               </li>
                           )}
                           {/*{profile.delivery.min_order_price && (*/}
                           {/*    <li>*/}
                           {/*        <span>*/}
                           {/*            <CartSVG /> {profile.delivery.min_order_price}*/}
                           {/*        </span>*/}
                           {/*    </li>*/}
                           {/*)}*/}
                       </>
                   )}
               </ul>
               <a target="_parent" href={profile.store_url} className={"stretched-link"} onClick={(e) => {e.preventDefault();openStore(profile.store_url)}}>
                   <div className="icon-go">
                       <AngelRightSVG/>
                   </div>
               </a>
           </div>
       </>
    );
};

export default EaterItem;
