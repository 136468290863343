import React, {FC, useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router-dom";
import EaterSearch, {SearchOptionsProps} from "../components/Search";
import {api} from "../../../services/Api/api";
import {ProfileApi} from "../../../services/Api/types";
import {Loading, LoadingContainer} from "../../../components/Common/Loading/Loading";
import EaterList from "../components/List";
import EaterMap from "../components/Map";
import {LoadScript} from "@react-google-maps/api";
import {AppConfig} from "../../../services/appConfig";
import {StoreFilter} from "../components/services/types";
import IframeUtils from "../../../utils/IframeUtils";
import Utils from "../../../utils/Utils";
import StorageStore from "../../../services/storageStore";
import goposSmall from '../../../images/logo/gopos-small.png';
import OrderTypeComponnent from "./components/orderType";
import ChooseViewComponnent from "./components/chooseView";
import {UserContext} from "../../../services/UserContext";
import LoginModal from "../components/User/loginModal";

const apiKey = "AIzaSyCvk6de_r1U3oKMbWOY1DztMTWMcxeKMaE";

let options = {
    search: {
        order_type_filter: false
    } as SearchOptionsProps
}
const DashboardEaterPage: FC<RouteComponentProps> = (props) => {
    const [profiles, setProfiles] = useState<ProfileApi[]>();
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState<StoreFilter>({} as StoreFilter);
    const [showProfile, setShowProfile] = useState<string | undefined>(undefined);
    const [map, setMap] = useState(false);
    const [viewType, setViewType] = useState("list");
    const userContext = React.useContext(UserContext);
    const [errorGmaps, setErrorGmaps] = useState(false);
    let mapEnabled = AppConfig.isOnlyMobile();
    let config = AppConfig.config;

    const isErrorLocation = (filter: StoreFilter) => {
        return filter.address !== undefined && filter.coordinates === undefined;
    }
    const searchProfiles = async (filter: StoreFilter | undefined) => {
        setLoading(true);
        let coordinates = undefined;
        if(filter !== undefined){
            coordinates = filter.coordinates !== undefined ? filter.coordinates.latitude + "," + filter.coordinates.longitude : undefined;
        }
        let params = filter !== undefined ? {
            coordinates: coordinates,
            type: filter.type,
            domains: AppConfig.getDomains().join(","),
            showOutsideCoordinates: filter.type === undefined || filter.type === "PICK_UP"
        } : {};
        params.domains = AppConfig.getDomains().join(",");
        const profilesResponse = await api.getProfiles(params);
        if (profilesResponse !== undefined) {
            let sortedProfiles = refreshCoordinates(profilesResponse, filter);
            setProfiles(sortedProfiles);
        }
        setLoading(false);
    }
    const refreshCoordinates = (profiles : ProfileApi[], newFilter: StoreFilter | undefined) => {
        if(!newFilter) newFilter = filter;
        let coordinates = newFilter ? newFilter.coordinates : undefined;
        let sortedProfiles = profiles;

        if(coordinates !== undefined){
            sortedProfiles.forEach(x => {
                if(coordinates === undefined || x.coordinates === undefined) {
                    x.distance = undefined;
                    return;
                }
                x.distance = Utils.calculateDistance(coordinates?.latitude, coordinates?.longitude, x.coordinates.latitude, x.coordinates.longitude);
            });
            sortedProfiles = sortedProfiles.sort((a, b) => {
                if(newFilter?.type !== "DINE_IN" && a.in_delivery_zone !== undefined){
                    if(a.in_delivery_zone && !b.in_delivery_zone) return -1;
                    if(!a.in_delivery_zone && b.in_delivery_zone) return 1;
                }
                if(a.distance == undefined) return 1;
                if(b.distance == undefined) return -1;
                if(a.distance > b.distance) return 1;
                if(a.distance < b.distance) return -1;
                return 0;
            });
        }else{
            sortedProfiles = sortedProfiles.sort((a, b) => a.name.localeCompare(b.name));
        }
        return sortedProfiles;
    }

    useEffect(() => {
        let locationJson = StorageStore.getItem('location');
        let coordinatesJson = StorageStore.getItem('coordinates');
        let location = JSON.parse(locationJson);
        let coordinates = JSON.parse(coordinatesJson);
        let newFilter = {} as StoreFilter;
        let newSearchFilter = {} as StoreFilter;
        let existFilter = false;
        if (location !== undefined && location !== null) {
            newFilter.address = location;
            newSearchFilter.address = location;
            existFilter = true;
        }
        if (coordinates !== undefined && coordinates !== null) {
            newFilter.coordinates = coordinates;
            existFilter = true;
        }
        if (existFilter)
            setFilter(newFilter);
        searchProfiles(newFilter)
    }, [])

    let search = (filter: StoreFilter) => {
        setFilter(filter);
        searchProfiles(filter)

        if (filter.coordinates !== undefined) {
            StorageStore.setItem("coordinates", JSON.stringify(filter.coordinates));
        } else {
            StorageStore.removeItem("coordinates");
        }
        if (filter.address !== undefined) {
            StorageStore.setItem("location", JSON.stringify(filter.address));
        } else {
            StorageStore.removeItem("location");
        }
    }
    const onChangeCoordinates = (coordinates: any) => {
        let newFilter = filter;
        newFilter.coordinates = coordinates;
        search(newFilter)
    }
    const onChangeOrderType = (type: string) => {
        let newFilter = filter;
        newFilter.type = type;
        search(newFilter)
    }

    const onChangeViewType = (type: string) => {
        setViewType(type);
    }


    let openStore = (url: string) => {
        let urlParams = {} as any
        let typeParam = "";
        if (filter !== undefined) {
            if (filter.address !== undefined) {
                urlParams.city = filter.address?.city
                urlParams.street = filter.address?.street
                urlParams.build_nr = filter.address?.build_nr
            }
            if (filter.type !== undefined) {
                typeParam = "/" + filter.type
            }
        }
        if(userContext.user){
            urlParams.user_token = userContext.token;
        }
        let urlToRedirect = `${url}${AppConfig.getPrefixRedirect()}` + typeParam + Utils.getUrlParams(urlParams);
        if (AppConfig.isRedirectInside()) {
            IframeUtils.redirectInside(urlToRedirect);
        } else if (AppConfig.isRedirectFull()) {
            IframeUtils.redirectFull(urlToRedirect);
        }else {
            IframeUtils.redirect(urlToRedirect);
        }
    }

    let showStore = (domain: string | undefined) => {
        setShowProfile(domain);
    }
    let renderFilter = (filter: StoreFilter) => {
        return (
            <>
                {filter.type !== undefined && (
                    <p>{filter.type}</p>
                )}
                {filter.coordinates !== undefined && (
                    <p>{filter.coordinates.latitude}, {filter.coordinates.longitude}</p>
                )}
                {filter.address !== undefined && (
                    <p>{filter.address.city}, {filter.address.street}</p>
                )}
            </>
        )
    }
    const orErrorLoadedGoogleMaps = (error:any) => {
        setErrorGmaps(true)
    }

    if(errorGmaps){
        mapEnabled = false;
    }
    const renderBody = () => {
        return (
            <div className={`eater eater-fixed1${viewType == "list" ? " list" : " map"}`}>
                <div className="container">
                    <div className="row gx-3">
                        <div className="col-sm-6 eater-left">
                            <OrderTypeComponnent type={filter.type} onChange={onChangeOrderType}/>
                            <ChooseViewComponnent type={viewType} onChange={onChangeViewType} />
                            <EaterSearch onChangeCoordinates={onChangeCoordinates} options={options.search} search={search} filter={filter} mapEnable={mapEnabled} map={map} toggleMap={() => setMap(!map)}/>
                            {isErrorLocation(filter) ? (
                                <div className={"alert alert-danger"}>Nie udało się znaleźć adresu</div>
                            ) : (
                                <>
                                    {loading ? <LoadingContainer/> :
                                        (
                                            <>
                                                {(!mapEnabled || !map) ? (
                                                    <EaterList coordinates={filter.coordinates} profiles={profiles} filter={filter}
                                                               openStore={openStore} showStore={showStore}/>
                                                ) : null}
                                            </>
                                        )}
                                </>
                            )}
                            {/*{renderFilter(filter)}*/}
                        </div>

                        <div className="col-sm-6">
                            {(!mapEnabled || map) ? (
                                <>
                                    {errorGmaps ? (
                                        <div className={"alert alert-danger"}>Błąd podczas pobierania map Google</div>
                                    ) : (
                                        <EaterMap config={config ? config.module_stores : undefined} coordinates={filter.coordinates} profiles={profiles} openStore={openStore}
                                                  showProfile={showProfile}/>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="footer__use">
                    <img src={goposSmall}/>
                    <a href="https://gopos.pl/goorder" target="_blank"
                       title="GoOrder - Zamówienia online dla gastronomii">
                        Używamy system zamówień online GoOrder
                    </a>
                </div>
            </div>
        )
    }
    if(errorGmaps){
        return (
            <>
                {renderBody()}
            </>
        )
    }
    return (
        <>
            <LoadScript
                googleMapsApiKey={apiKey}
                libraries={["places"]}
                onError={orErrorLoadedGoogleMaps}
                loadingElement={<Loading/>}
            >
                {renderBody()}
            </LoadScript>
        </>
    );
};

export default DashboardEaterPage;
