import React, {FC} from "react";


interface Props {
    onChange: (type: string) => void;
    type?: string
}
const OrderTypeComponnent: FC<Props> = (props) => {

     return (
         <>
             <ul className="nav nav-underline nav-order-type nav-fill nav-justified pb-3">
                 <li className="nav-item">
                     <a className={`nav-link${props.type == "DELIVERY" ? " active" : ""}`} aria-current="page" href="#" onClick={() => props.onChange("DELIVERY")}>
                         Dowóz
                     </a>
                 </li>
                 <li className="nav-item">
                     <a className={`nav-link${props.type == "PICK_UP" ? " active" : ""}`} href="#" onClick={() => props.onChange("PICK_UP")}>
                         Odbiór osobisty
                     </a>
                 </li>
             </ul>
         </>
     )
};

export default OrderTypeComponnent;
