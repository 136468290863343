import React, {FC} from "react";
import {Coordinates, ProfileApi} from "../../../../services/Api/types";
import EaterItem from "../Item";
import Utils from "../../../../utils/Utils";
import {StoreFilter} from "../services/types";

interface Props {
    profiles: ProfileApi[] | undefined,
    openStore: (url: any) => void,
    showStore: (domain: string | undefined) => void,
    coordinates: Coordinates | undefined,
    filter: StoreFilter | undefined
}

const EaterList: FC<Props> = (props) => {
    if (props.profiles === undefined) {
        return null;
    }
    let showProfile = (domain: string | undefined) => {
        props.showStore(domain);
    }

    let sortedProfiles = props.profiles;

    return (
        <div className="eater-list">
            {props.profiles.length <= 0 ?
                (
                    <div className={"text-center"}>
                        Nie znaleziono żadnej lokalizacji dostępnej w wybranym adresie.
                    </div>
                ) : (
                    <>
                        {sortedProfiles.map(profile => {
                            return (
                                <div key={profile.domain} className={"card-profile"} onMouseEnter={() => showProfile(profile.domain)} onMouseLeave={() => showProfile(undefined)}>
                                    <EaterItem openStore={props.openStore} profile={profile} showUnavailable={props.filter?.type === undefined || props.filter?.type == "DELIVERY"} />
                                </div>
                            )
                        })}
                    </>
                )}
        </div>
    );
};

export default EaterList;
