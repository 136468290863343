import Utils from "../utils/Utils";
import {ConfigApi} from "./Api/types";
import {api} from "./Api/api";

class AppConfig {

    static widget = false;
    static redirectType = undefined as string | undefined;  //  FULL, INSIDE
    static color = undefined as string | undefined;
    static darkMode = false;
    static gmaps = false;
    static storeIds = [];
    static domains = [] as string[];
    static prefix = "widget" // none, widget
    static widgetRedirect = undefined as string | undefined;    // none, widget, widget_native
    static config = undefined as ConfigApi | undefined
    static onlyMobile = false
    static basename = "/"
    static golayalty = false
    static configName = ""


    static setWidget(widget: boolean) {
        this.widget = widget;
        // this.initQueryParams();
    }
    static setGoLoyalty(golayalty: boolean) {
        this.golayalty = golayalty;
        // this.initQueryParams();
    }

    static initConfig(configName : string, config?: ConfigApi){
        this.configName = configName;
        api.restoreBaseUrl();
        if(config){
            this.config = config;
            if(config.base_url){
                api.setBaseUrl(config.base_url);
            }
        }
        if(config && config.module_stores){
            let configItem = config.module_stores;
            if(configItem.domain_slugs){
                let newDomains = [];
                if(Array.isArray(configItem.domain_slugs)){
                    newDomains = configItem.domain_slugs;
                }else{
                    newDomains = configItem.domain_slugs.split(",");
                }
                this.domains = newDomains.map(x => {
                    if(x.includes("http") || x.includes(".goorder.") || x == "all") return x;
                    if(x.includes("inner-")) return x.replace("inner-", "");
                    return x + ".goorder.pl";
                })
            }else if(configItem.domains){
                this.domains = configItem.domains.split(",");
            }
            if(configItem.redirect_type){
                this.redirectType = configItem.redirect_type;
            }
            if(configItem.widget_redirect){
                this.widgetRedirect = configItem.widget_redirect;
            }
        }
    }
    static initQueryParams() {
        if (window.location.search) {
            let query = window.location.search.substring(1);
            let qp = Utils.getQueryParams(query);
            if (qp.full) {
                this.redirectType = "FULL";
            }
            if (qp.inside) {
                this.redirectType = "INSIDE";
            }
            if (qp.widgetRedirect) {
                this.widgetRedirect = qp.widgetRedirect;
            }

            if (qp.dark) {
                this.setDarkMode()
            }
            if(qp.only_mobile || qp.onlyMobile){
                this.onlyMobile = true;
            }
            if (qp.gmaps) {
                this.gmaps = true;
            }
            if (qp.storeIds) {
                this.storeIds = qp.storeIds.split(",");
            }
            if (qp.store_ids) {
                this.storeIds = qp.store_ids.split(",");
            }
            if (qp.domains) {
                this.domains = qp.domains.split(",");
            }
            if (qp.color) {
                this.color = qp.color;
            }
        }
    }

    static setPrefix(prefix: string) {
        this.prefix = prefix;
    }

    static setBaseName(basename: string) {
        if(basename){
            basename = basename.endsWith("/") ? basename.slice(0, -1) : basename;
        }
        this.basename = basename;
    }

    static getPrefixRedirect(){
        if(Utils.isNative()){
            return "/widget_native"
        }
        switch (this.widgetRedirect){
            case "widget_native":
                return "/widget_native"
            case "widget":
                return "/widget"
        }
        return "";
    }

    static getPrefix() {
        return this.prefix;
    }

    static getStoreIds() {
        return this.storeIds;
    }

    static getDomains() {
        return this.domains;
    }

    static setDarkMode() {
        this.darkMode = true;
        var body = document.body;
        body.classList.add("dark-mode");
    }

    static isWidget() {
        return this.widget;
    }

    static isOnlyMobile(){
        return this.onlyMobile;
    }

    static isGmaps() {
        return this.gmaps;
    }

    static getColor() {
        if(this.color) return this.color;
        return this.config?.color;
    }

    static getSearchCitiesType() {
        return this.config?.module_stores.search_cities;
    }

    static getSearchAddressType() {
        return this.config?.module_stores.search_adddress_type;
    }

    static isRedirectInside() {
        return this.redirectType == "INSIDE";
    }

    static isRedirectFull() {
        return this.redirectType == "FULL";
    }

    static isRedirectDefault() {
        return this.redirectType === undefined;
    }
}

export {AppConfig}