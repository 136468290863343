import React, {FC} from "react";


interface Props {
    onChange: (type: string) => void;
    type?: string
}
const ChooseViewComponnent: FC<Props> = (props) => {

     return (
         <>
             <div className="btn-group btn-choose-view pb-3" role="group" aria-label="Large button group">
                 <button type="button" className={`btn btn-outline-dark${props.type == "list" ? " active" : ""}`} onClick={() => props.onChange("list")}>Lista lokali</button>
                 <button type="button" className={`btn btn-outline-dark${props.type == "map" ? " active" : ""}`} onClick={() => props.onChange("map")}>Pokaż na mapie</button>
             </div>
         </>
     )
};

export default ChooseViewComponnent;
