import React, {FC, useEffect, useState} from "react";
import {Button, Dropdown, DropdownButton, InputGroup, Spinner} from "react-bootstrap";
import {TypeaheadComponentProps} from "react-bootstrap-typeahead/types/components/Typeahead";
import Utils from "../../../../utils/Utils";
import Typeahead2 from "react-bootstrap-typeahead/types/core/Typeahead";
import {Address, Coordinates} from "../../../../services/Api/types";
import {AddressFilter, StoreFilter} from "../services/types";
import {ReactComponent as MapSVG} from "../../../../images/svg/map.svg";
import {ReactComponent as ListSVG} from "../../../../images/svg/list.svg";
import {ReactComponent as LocationSVG} from "../../../../images/svg/location.svg";
import FormAddressComponent from "./components/FormAddressComponent";


export interface SearchOptionsProps {
    order_type_filter: boolean
    // updateCoordinates: (coordinates: Coordinates | undefined) => void;
}
interface Props {
    search: (filter: StoreFilter) => void;
    onChangeCoordinates: (coordinates: Coordinates) => void;
    filter: StoreFilter,
    mapEnable: boolean,
    map: boolean,
    toggleMap: () => void,
    options: SearchOptionsProps
    // updateCoordinates: (coordinates: Coordinates | undefined) => void;
}

const EaterSearch: FC<Props> = (props) => {
    const [address, setAddress] = useState<any>(props.filter.address);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [formAddress, setFormAddress] = useState(false);
    const typeaheadRef = React.createRef<Typeahead2>();
    useEffect(() => {
    }, [])

    const geolocation = () => {
        if ("geolocation" in navigator) {
        } else {
            alert("Geolokalizacja niedostępna")
            return;
        }
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(function(position) {
            props.onChangeCoordinates({latitude: position.coords.latitude, longitude: position.coords.longitude} as Coordinates)
            setLoadingLocation(false);
        }, (err) => {
            console.log(err);
            alert("Geolokalizacja niedostępna: " + err.message)
            alert("Geolokalizacja niedostępna")
            setLoadingLocation(false);
        });
    }

    let updateType = (type:string | undefined) => {
        let filter = {...props.filter};
        filter.type = type;
        props.search(filter);
    }


    let showAddressModal = () => {
        if(formAddress){
            setFormAddress(false);
            // typeaheadRef?.current?.hideMenu();
            return;
        }
        setFormAddress(true);
        typeaheadRef?.current?.hideMenu();
    }
    let clearAddress = async () => {
        props.search({} as StoreFilter);
        setAddress({})
        setFormAddress(false);
    }
    const onUpdateAddress = (address : Address, coordinates: Coordinates) => {

        // address.street + " " + address.build_nr + ", " + address.city
        let fullName = Utils.parseAddressToString(address);
        let filter = {...props.filter};
        setAddress(address)
        setFormAddress(false);
        filter.coordinates = coordinates;
        filter.address = {
            city: address.city,
            street: address.street,
            build_nr: address.build_nr,
            zip_code: address.zip_code,
            full: fullName
        } as AddressFilter;
        props.search(filter);
    }

    let renderSelected = (address: Address) => {
        if(address === undefined) return "Wpisz adres dostawy";
        let addressString = Utils.parseAddressToString(address);
        if(!addressString) return "Wpisz adres dostawy";
        return addressString;
    }
    let options = {} as TypeaheadComponentProps
    return (
      <div className="search mb-3">
          <InputGroup>

              <div
                 className={"btn btn-search"}
                 defaultValue={renderSelected(address)}
                 onClick={showAddressModal}
              >
                  {renderSelected(address)}
                  <div className="rbt-aux" onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      clearAddress();
                  }}>
                      <button aria-label="Clear" className="close rbt-close" type="button"><span
                          aria-hidden="true">×</span><span className="sr-only visually-hidden">Clear</span></button>
                  </div>
              </div>

              <Button className={"btn btn-primary"} disabled={loadingLocation} onClick={geolocation}>
                  {loadingLocation ? (
                      <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      />
                  ) : (
                      <LocationSVG/>
                  )}
              </Button>
              {props.options.order_type_filter && (
                  <>
                      <DropdownButton
                          // as={InputGroup.Append}
                          variant="type"
                          title={props.filter === undefined || props.filter.type === undefined ? "Wszystkie" : props.filter.type}
                          id="input-group-dropdown-2"
                          className={"btn-type"}
                      >
                          <Dropdown.Item href="#" onClick={() => updateType(undefined)}>Wszystkie</Dropdown.Item>
                          <Dropdown.Item href="#" onClick={() => updateType("DELIVERY")}>Dowóz</Dropdown.Item>
                          <Dropdown.Item href="#" onClick={() => updateType("PICK_UP")}>Odbiór osobisty</Dropdown.Item>
                          <Dropdown.Item href="#" onClick={() => updateType("DINE_IN")}>Na miejscu</Dropdown.Item>
                      </DropdownButton>
                      {props.mapEnable && (
                          <Button variant={"default"} className={"btn-map"} onClick={() => props.toggleMap()}>
                              {props.map ? (
                                  <ListSVG/>
                              ) : (
                                  <MapSVG/>
                              )}
                          </Button>
                      )}
                  </>
              )}
          </InputGroup>
          {formAddress && (
              <>
                  <FormAddressComponent address={address} onCancel={() => setFormAddress(false)} onChange={onUpdateAddress}/>
              </>
          )}
      </div>
  );
};

export default EaterSearch;
