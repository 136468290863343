import React from 'react';
import {api} from "./Api/api";
import {ConfigModuleCrmApi, ContactApi, LoginApi} from "./Api/types";
import StorageStore from "./storageStore";
import {ForbiddenException, NotFoundException, UnauthorizedException} from "./Api/exceptions";
import Utils from "../utils/Utils";

interface UserContextType {
    user?: ContactApi;
    token?: string;
    login: (loginApi: LoginApi) => void;
    logout: () => void;
}

export class UserService {
    portalStore?: string;
    user?: ContactApi;
    token?: string;
    onLogin: (login: LoginApi) => void;
    onLogout: () => void;
    config?: ConfigModuleCrmApi

    constructor(
        login: (login: LoginApi) => void,
        logout: () => void,
        portalStore?: string,
        user?: LoginApi,
        config?: ConfigModuleCrmApi
    ) {
        this.portalStore = portalStore;
        this.user = user ? user.contact : undefined;
        this.token = user ? user.token : undefined;
        this.onLogin = login;
        this.onLogout = logout;
        this.config = config;
    }

    isEnabled(){
        return this.config && ((this.config.native && Utils.isNative()) || (this.config.web && !Utils.isNative()));
    }

    async login(name: string, pasword: string): Promise<any> {
        if(!this.portalStore) throw new Error();
        let response = await api.login(this.portalStore, name, pasword);
        if(response){
            this.onLogin(response);
            StorageStore.setItem("user", JSON.stringify(response));
        }
        return this.user;
    }

    async register(email: string, name:string, phone: string, pasword: string, confirmPassword: string, acceptRules: boolean, acceptMarketingRules: boolean): Promise<any> {
        if(!this.portalStore) throw new Error();
        let response = await api.register(this.portalStore, email, name, phone, pasword, confirmPassword, acceptRules, acceptMarketingRules);
        if(response){
            this.onLogin(response);
            StorageStore.setItem("user", JSON.stringify(response));
        }
        return this.user;
    }
    async resetPassword(email: string): Promise<any> {
        if(!this.portalStore) throw new Error();
        let response = await api.resetPassword(this.portalStore, email);
    }
    async changePassword(email: string, code: string, password : string, confirmPassword: string): Promise<any> {
        if(!this.portalStore) throw new Error();
        let response = await api.changePassword(this.portalStore, email, code, password, confirmPassword);
        if(response){
            this.onLogin(response);
            StorageStore.setItem("user", JSON.stringify(response));
        }
        return this.user;
    }
    async sendVerificationLink(): Promise<any> {
        if(!this.portalStore) throw new Error();
        if(!this.user?.email) return ;
        let response = await api.sendActivationLink(this.portalStore, this.user?.email);
    }
    async activation(email: string, code: string): Promise<any> {
        if(!this.portalStore) throw new Error();
        let response = await api.registerActivation(this.portalStore, email, code);
        if(response){
            this.onLogin(response);
            StorageStore.setItem("user", JSON.stringify(response));
        }
        return this.user;
    }
    async logout(): Promise<any> {
        this.onLogout();
        StorageStore.removeItem("user");
        return this.user;
    }
    async getMe() : Promise<any> {
        if(!this.portalStore) throw new Error();
        if(!this.token) return;
        try {
            let response = await api.getMe(this.portalStore, this.token);
            return response;
        }catch (err){
            return undefined;
        }
    }
    async initUser(): Promise<any> {
        if(!this.portalStore) throw new Error();
        let userJson = StorageStore.getItem('user');
        let user = JSON.parse(userJson);
        if(!user || !user.token) return;
        try {
            let response = await api.getMe(this.portalStore, user.token);
            if(!response){
                await this.logout();
                return;
            }
            this.onLogin({
                contact: response,
                token: user.token
            });
        }catch (err){
            if(err instanceof NotFoundException ||
                err instanceof ForbiddenException ||
                err instanceof UnauthorizedException){
                await this.logout();
            }
        }
    }
}

export const UserContext = React.createContext<UserService>({} as UserService);
