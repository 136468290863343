import React, {CSSProperties, FC, useState} from "react";
import {Container, Dropdown, Nav, Navbar} from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import {ConfigApi} from "../../../../services/Api/types";
import {Link, useHistory} from "react-router-dom";
import {ReactComponent as BarsSVG} from "../../../../images/svg/bars.svg";
import {ReactComponent as UserSVG} from "../../../../images/svg/user.svg";
import {ReactComponent as LockSVG} from "../../../../images/svg/lock.svg";
import {UserContext} from "../../../../services/UserContext";
import LoginModal from "../User/loginModal";
import Utils from "../../../../utils/Utils";


interface Props {
    config?: ConfigApi
}

// max-width: 120px;
// object-fit: contain;
// max-height: 100%;
const logoStyle = {
    maxWidth: "120px",
    objectFit: "contain",
    maxHeight: "100%"
} as CSSProperties
const NavbarDrawer: FC<Props> = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [modalLogin, setModalLogin] = useState(false)
    const userContext = React.useContext(UserContext);
    let history = useHistory();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const handleClose = () => setMenuOpen(false)
    if(!props.config) return null;
    let expand = "lg";
    let user = userContext.user;
    let token = userContext.token;
    const logout = async () => {
        await userContext.logout();
    }
    const prefixLink = (path : string) => {
        return path;
    }
    const renderLogo = () => {

        if(!Utils.isNative() && props.config?.home_url){
            return (
                <a href={props.config?.home_url}>
                    {props.config.logo ? (
                        <img src={props.config.logo} style={logoStyle} />
                    ) : "Logo"}
                </a>
            )
        }
        return (
            <Navbar.Brand as={Link} to={prefixLink("/")}>
                {(props.config && props.config.logo) ? (
                    <img src={props.config.logo} style={logoStyle} />
                ) : "Logo"}
            </Navbar.Brand>
        )
    }
    return (
        <>
            <Navbar sticky="top" expand={expand} className="mb-3 bg-white">
                <Container fluid>
                    {renderLogo()}
                    <div className={"navbar-menus"}>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
                                       onClick={toggleMenu}>
                            <BarsSVG/>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            restoreFocus={false}
                            show={menuOpen}
                            onHide={handleClose}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    {props.config.logo ? (
                                        <img src={props.config.logo} style={logoStyle} />
                                    ) : "Logo"}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end1 flex-grow-1">
                                    {props.config.menus.map(item => {
                                        switch (item.type){
                                            case "stores":
                                                return (
                                                    <Nav.Link key={item.title} as={Link} to={prefixLink("/")} >{item.title}</Nav.Link>
                                                );
                                        }
                                        return (
                                            <Nav.Link key={item.title} href={item.url}>{item.title}</Nav.Link>
                                        );
                                    })}
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                        {userContext.isEnabled() && (
                            <>
                                {user ? (
                                    <Dropdown className={"btn-user"}>
                                        <Dropdown.Toggle className={""} variant="transparent" id="dropdown-basic">
                                            {/*<span className={"badge bg-success me-2"}>+{user.points} punktów</span>*/}
                                            <UserSVG/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align={"end"}>
                                            <Dropdown.Item>
                                                {user.name}<br />
                                                {user.points !== undefined && (
                                                    <span className={"badge bg-success me-2"}>Punktów: {user.points}</span>
                                                )}
                                            </Dropdown.Item>
                                            <Dropdown.Divider/>
                                            <Dropdown.Item onClick={() => logout()}>
                                                Wyloguj się
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    <>
                                        <button className={"btn btn-transparent"} onClick={() => setModalLogin(true)}>
                                            <LockSVG/>
                                        </button>
                                        {modalLogin && (
                                            <LoginModal config={props.config} show={true} onHide={() => setModalLogin(false)}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Container>
            </Navbar>
        </>
    )
};

export default NavbarDrawer;
