import React, {FC} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Page404 from "./components/Page404/Page404";
import AppEaterPage from "./pages/AppEater";
import {AppConfig} from "./services/appConfig";

const Routes: FC = () => {
    let basename = AppConfig.basename;
  return (
    <BrowserRouter basename={basename}>
        <Switch>
            {/*<Route path="/" component={UserPage}/>*/}
            <Route path="/portal/eater/widget" component={AppEaterPage}/>
            <Route path="/portal/eater/widget_native" component={AppEaterPage}/>
            <Route path="/portal/eater/:configId" component={AppEaterPage}/>
            <Route path="/portal/eater/" component={AppEaterPage}/>
            <Route path="/" component={AppEaterPage}/>
            {/*<Route path="/404" component={Page404}/>*/}
            {/*<Route component={Page404}/>*/}
        </Switch>
    </BrowserRouter>
  );
};

export default Routes;
